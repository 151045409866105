/* Global CSS */

body {
  padding-top: 75px;
}

h3.header {
  text-transform: uppercase;
  font-size: 1.2em;
  letter-spacing: 5px;
  font-weight: 600;
}

hr.white {
  border: 3px #fff solid;
}

hr.orange {
  border: 3px #f79421 solid;
}

.btn-orange {
  background: #f79421;
  color: #fff;
  border-radius: 0;
}

.white {
  color: #fff;
}

.grey {
  color: #333;
}

.orange {
  color: #f79421;
}

.entry-content iframe {
  margin: 0 auto;
  display: block;
}

@media (min-width: 992px) {
  .row-centered {
    text-align: center;
  }

  .col-centered {
    display: inline-block;
    float: none;
    /* reset the text-align */
    text-align: left;
    /* inline-block space fix */
    margin-right: -4px;
  }
}

.responsive-container {
  position: relative;
  padding-bottom: 50.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 1em;
}
.responsive-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* navigation */

.navbar-brand img {
  width: 60%;
}

.navbar-default {
  z-index: 5;
  background: #fff;
  min-height: 75px;
}

ul.nav {
  margin-top: 13px;
  padding-left: 0px;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 200;
}

.sm-img {
  width: 60px;
}

.sm-img img {
  width: 100%;
  margin-top: -5px;
}

.navbar-brand {
  padding: 5px 15px;
}

/* Intro */

.caption {
  z-index: 4;
  width: 560px;
  position: absolute;
  right: 11%;
  top: 35%;
  color: #fff;
  text-align: justify;
}

.caption h1 {
  font-size: 3em;
  color: #f79421;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-decoration: underline;
}

.caption p {
  font-weight: 300;
  font-size: 1.4em;
}

/* About */
.about {
  /* background: url("/images/about-1.jpg") center center no-repeat; */
  background-size: cover;
  padding: 50px 0px 100px 0px;
  position: relative;
}

.about h2 {
  font-size: 3em;
  color: #f79421;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 600;
}

/* Team */
.team {
  background: #f79421;
  padding: 10px;
}

.team .col-md-1 {
  width: 14%;
}

.team img {
  width: 80%;
  display: block;
  margin: 0 auto;
}

.team h2 {
  font-size: 3em;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 600;
}

.team h4,
.team h5 {
  text-align: center;
  text-transform: uppercase;
}

.team h4 {
  font-size: 1em;
  letter-spacing: 1px;
  color: #fff;
}

.team h5 {
  font-size: 0.8em;
  line-height: 1.2em;
  text-align: center;
  color: #000;
}

.team-detail h2 {
  text-transform: uppercase;
}

.team-detail p {
  font-size: 17px;
}

.team-detail .title {
  font-size: 20px !important;
  margin-left: 15px;
  font-style: italic;
}

/* Endorsements */
.title a {
  font-size: 2em;
}

.post {
  border-bottom: 1px solid #000;
  padding: 10px;
}

/* Vox Social */

.vox-social {
  padding-top: 10px;
  padding-bottom: 60px;
  background-color: #000;
  background: linear-gradient(to right, #000 49%, #fff 49%, #fff 100%);
}

.vox-social h2 {
  font-size: 3em;
  color: #f79421;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 600;
}

.vox-social .col-md-6 img {
  width: 55%;
}

.vox-social .right {
}

.vox-social p {
  font-size: 1.3em;
  font-weight: 200;
}

.vox-social .features p {
  font-size: 1.2em;
  margin-top: 25px;
  margin-bottom: 25px;
}

.row.features img {
  width: 100%;
}

/* Contact */

.contact {
  background: url("/images/contact-bg.jpg") center center no-repeat;
  background-size: cover;
  padding: 100px 0;
}

.contact h1 {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 600;
  margin-top: 30px;
}

.contact h2 {
  margin-top: 40px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.contact h2 a {
  color: #fff;
  border-bottom: 2px solid #f79421;
}

.contact .right h3 {
  font-size: 1.8em;
  font-weight: 200;
}

.contact .col-md-3 img {
  width: 100%;
}

.contact .col-md-9 {
  font-size: 1.3em;
  color: #fff;
  text-transform: uppercase;
}

footer p {
  display: inline;
  font-size: 1.4em;
  font-weight: 400;
  margin-left: 20px;
  text-transform: uppercase;
}

footer .nav a {
  font-size: 1.2em;
  color: #000;
}

/* modal */
.modal-body img {
  width: 100%;
}

/* Helper Classes */

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pad-top-20 {
  padding-top: 20px;
}

.pad-top-60 {
  padding-top: 60px;
}

.pad-bottom-20 {
  padding-bottom: 20px;
}

.img-center {
  display: block;
  margin: 0 auto;
}

/* Carousel Fade Helper */

.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
  WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
  Need to override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@media only screen and (min-device-width: 668px) and (max-device-width: 1105px) {
  .caption {
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    color: #000000;
    padding: 2rem;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  body {
    padding-top: 80px;
  }
  .about {
    background: none;
    padding: 20px 0px 50px 0px;
  }

  .caption {
    width: 300px;
    right: 10%;
    top: 6%;
    text-align: center;

    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    color: #000000;
    padding: 2rem;
  }

  .why-btn {
    display: block;
    margin: 0 auto;
    float: none !important;
  }

  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img,
  .img-responsive,
  .thumbnail a > img,
  .thumbnail > img {
    max-width: 500%;
    height: 400px;
  }

  .navbar-default {
    min-height: 75px;
  }

  .navbar-brand img {
    width: 50%;
  }
  div#navbar {
    margin-top: 30px;
  }

  .team .col-md-1 {
    width: 50%;
    float: left;
    height: 300px;
  }

  .vox-social {
    background: #000 !important;
  }

  .vox-social .col-md-6 img {
    display: block;
    margin: 0 auto;
  }

  .contact .col-md-3 img {
    width: 80%;
  }

  .backstretch {
    display: none;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  body {
    padding-top: 80px;
  }
  .about {
    background: none;
    padding: 20px 0px 50px 0px;
  }

  .caption {
    width: 260px;
    right: 9%;
    top: 6%;
    text-align: center;

    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    color: #000000;
    padding: 2rem;
  }

  .backstretch {
    display: none;
  }

  .caption h1 {
    font-size: 2em;
  }

  .why-btn {
    display: block;
    margin: 0 auto;
    float: none !important;
  }

  .carousel-inner > .item > a > img,
  .carousel-inner > .item > img,
  .img-responsive,
  .thumbnail a > img,
  .thumbnail > img {
    max-width: 500%;
    height: 400px;
  }

  .navbar-default {
    min-height: 75px;
  }

  .navbar-brand img {
    width: 50%;
  }
  div#navbar {
    margin-top: 30px;
  }

  .team .col-md-1 {
    width: 50%;
    float: left;
    height: 300px;
  }

  .vox-social {
    background: #000 !important;
  }

  .vox-social .col-md-6 img {
    display: block;
    margin: 0 auto;
  }

  .contact .col-md-3 img {
    width: 80%;
  }

  footer img {
    width: 30%;
  }

  footer p {
    font-size: 1em;
  }
}
